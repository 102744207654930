import '../stylesheets/Landing.scss';
import { useEffect, useCallback, useState } from 'react';
import { getLocal, setLocal } from '../../utils/dataFetching';
import {getEvalByModule} from "../../utils/fetch/llmFetching";
import {formattedDate, formatTimeWithZone} from "../../utils/helpers";
import Overlay from "./Overlay";
import { useErrorBoundary } from "react-error-boundary";



const Landing = ({scenario}) => {
  const { showBoundary } = useErrorBoundary();

  const [tenant,] = useState(getLocal('tenant'));
  const rpModId = scenario?._id;
  let limit = scenario?.evalLimit;
  
  const [evaluations, setEvaluations] = useState([]);
  const [evalCount, setEvalCount] = useState(0);
  const [overlayOpen, setOverlayOpen] = useState(false);

  if (scenario.avatarAudioOnly) {
    setLocal("audioOnly", true);
  } else {
    setLocal("audioOnly", false);
  }

  // Avatar Settings
  setLocal("avatar_url", process.env.REACT_APP_ASSETS_URL + '/_avatars/' + scenario?.avatar + '/image.png');
  setLocal("avatar_audio", scenario?.avatarVoice);

  const overlayToggle = (open) => {
    setOverlayOpen(open);
  }
  
  const getEvals = useCallback(async () => {
    try {
      if (rpModId) {
        const sessionRes = await getEvalByModule(rpModId);

        if (sessionRes.data?.evaluations) {
          setEvaluations(sessionRes.data.evaluations);
          setEvalCount(sessionRes.data.evaluations.length);
        }
      }
    } catch (e) {
      console.error('Error fetching eval history:', e);
      showBoundary(e);
    }


  }, [rpModId, showBoundary]);

  useEffect(() => {
    getEvals();
  }, [getEvals]);

  return (
    <div className="landing-container">
      <div className="logo-container"></div>
      <a className="app-link not-mobile" href={'/' + tenant?.slug + '/menu'}>&lt; menu</a>
      <a className="app-link mobile" href={'/' + tenant?.slug + '/menu'}>&lt; menu</a>
      <h3>{scenario?.title}</h3>
      <div className="customer-container">
        <img src={process.env.REACT_APP_ASSETS_URL + '/_avatars/' + scenario?.avatar + '/landing.png'} alt={scenario?.avatarName} />
      </div>
      <div className="description" dangerouslySetInnerHTML={{ __html: scenario?.instructions }} />
      {evalCount < limit ? (
        <button type="button" onClick={() => window.location = '/' + tenant?.slug + '/' + scenario?.slug}>Start</button>
      ) : (
        <p></p>
      )}

      {scenario.eval && (
        <div className="menu-btn">
          <button className="app-link" type="button" onClick={() => overlayToggle(true)}>view previous evaluations
          </button>
        </div>
      )}

      <Overlay isOpen={overlayOpen} onClose={() => overlayToggle(false)} headerTitle={"Performance Evaluations"}>
        <div className="eval-list">
            {evaluations?.length > 0 ? (
                <ul>
                  {evaluations.map((evalInfo, index) => (
                    <li key={index}>
                      <button type="button" onClick={() => window.location = '/' + tenant?.slug + '/' + scenario?.slug + '-evaluation/' + evalInfo._id}>Past Assessment: {formattedDate(evalInfo.updatedAt, true) + " - " + formatTimeWithZone(evalInfo.updatedAt, true)}</button>
                    </li>
                  ))}

                </ul>
              ) : (
                <p>No evaluations found.</p>
                // keep around to help with styling, making new sessions and evaluating them is time consuming
                // <ul>
                //   <li>
                //     <button type="button">Past Assessment: 6/13/2024 - 10:45 am</button>
                //   </li>
                // </ul>
            )}
          </div>
        </Overlay>
      
    </div>
  );
};

export default Landing;
