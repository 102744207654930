import axios from "axios";
import { isTokenExpired } from './auth';

let isProcess = false;
//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  async (request) => {

    const tokenCheck = getLocal('auth_token');
    const ccode = getLocal('ccode');

    if (tokenCheck) {
      const validToken = isTokenExpired(tokenCheck);
      if (ccode && validToken === false && !isProcess) {
        // Generate new token
        isProcess = true;
        await getToken(true);
        isProcess = false;
      }
    }

    const token = getLocal("auth_token");
    if (token) request.headers.authorization = `Bearer ${token}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        console.log('ATTEMPTING RESCUE OF ORIGINAL REQUEST');
        await getToken(true);
        return axios(originalRequest);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return Promise.reject(error);
  }
);


const setSess = (key, val) => {
  sessionStorage.setItem(key, val ? JSON.stringify(val) : false);
}

const getSess = (key) => {
  const v = sessionStorage.getItem(key);
  return v ? JSON.parse(v) : false;
}

const setLocal = (key, val) => {
  localStorage.setItem(key, val ? JSON.stringify(val) : false);
}

const setLocalAsync = (key, value) => {
  return new Promise((resolve) => {
    localStorage.setItem(key, value ? JSON.stringify(value) : false);
    resolve(true);
  });
};

const getLocal = (key) => {
  const v = localStorage.getItem(key);
  return v ? JSON.parse(v) : false;
}

const emptyLocal = () => {
  localStorage.clear();
}

//let tokenStatusTimer;
const getToken = async (fresh) => {
  const currentToken = getLocal("auth_token");
  const getTokenStatus = getSess("getToken_status");
  /*
  if (getTokenStatus) {
    if (tokenStatusTimer) {
      clearInterval(tokenStatusTimer);
    }
    tokenStatusTimer = setTimeout(() => {

      setSess("getToken_status", '');
    },2000);
    return getTokenStatus;
  }
  */
  if ((!currentToken || fresh) && !getTokenStatus) {
    /*
    setSess("getToken_status",'wait');

    tokenStatusTimer = setTimeout(() => {
      console.log('test2');
      setSess("getToken_status", '');
    },2000);
    */
    const accessCode = getLocal('ccode');

    const body = {
      code: accessCode
    }

    try {
      let tokenRes = await axios.post(`${process.env.REACT_APP_API}/api/token`, body);

      if (tokenRes.data && tokenRes.status === 200) {
        setLocal('tenant', {
          ...tokenRes.data.tenant,
          assistants: tokenRes.data.assistants,
          scenarios: tokenRes.data.scenarios,
          virtualInstructors: tokenRes.data.virtualInstructors
        });
        
        setLocal("auth_token", tokenRes.data.token);
        setLocal("d_id_key", tokenRes.data.apiKey);
        
        setLocal("avatar_url", process.env.REACT_APP_AVATAR_URL);
        setLocal("avatar_audio", process.env.REACT_APP_AVATAR_AUDIO);
        setLocal("kb_audio", process.env.REACT_APP_KB_AUDIO);
      }

      return tokenRes;
    } catch (e) {
      console.log(e);

      // DISABLED, no longer needed, since we are now using the token endpoint
      /*
      if (e.response.status === 401 && /not registered/.test(e.response.data) ) {
        let tokenRes = await axios.post(`${process.env.REACT_APP_API}/auth/signup`, body);
        if (tokenRes.data && tokenRes.status === 200) {
          tokenRes = await axios.post(`${process.env.REACT_APP_API}/auth/login`, body);
        }

        if (tokenRes.data && tokenRes.data.access_token && tokenRes.status === 200) {
          setLocal("auth_token", tokenRes.data.access_token);
          //setLocal("session_id", tokenRes.data.session._id);
          //setLocal("d_id_key", tokenRes.data.apiKey);
          setLocal("avatar_url", tokenRes.data.avatarUrl || process.env.REACT_APP_AVATAR_URL);
          setLocal("avatar_audio", tokenRes.data.avatarAudio || process.env.REACT_APP_AVATAR_AUDIO);
          setLocal("kb_audio", tokenRes.data.kbAudio || process.env.REACT_APP_KB_AUDIO);
          return tokenRes;
        }
      }
      */

      setSess("getToken_status", '');
      throw new Error(e);
    }

  }

  return {data: currentToken, status: 200};
};

export {
  getSess,
  getLocal,
  setSess,
  setLocal,
  setLocalAsync,
  getToken,
  emptyLocal
};
