import AvatarVideo from "../general/AvatarVideo";

import '../stylesheets/RolePlay.scss';
import Header from "../general/Header";
import {sessionCreate} from "../../utils/session/session";
import { setLocal } from '../../utils/dataFetching';
import { useEffect, useCallback, useState } from 'react';
import { useErrorBoundary } from "react-error-boundary";

const VirtualInstructor = ({vi: VI, audioRef}) => {
  console.log("INSIDE VIRTUAL INSTRUCTOR PAGE");
  const { showBoundary } = useErrorBoundary();

  const [viSessionId, setViSessionId] = useState('');
  const viModId = VI?._id;
  
  // Avatar Settings
  setLocal("avatar_url", process.env.REACT_APP_ASSETS_URL + '/_avatars/' + VI?.avatar + '/image.png');
  setLocal("avatar_audio", VI?.avatarVoice);

  const getSession = useCallback(async () => {
    try {
      if (!viSessionId) {
        const sessionRes = await sessionCreate(viModId);

        if (sessionRes.data?.session) {
          setViSessionId(sessionRes.data.session._id);
          setLocal('vi_'+ VI?.slug + '_session_id', sessionRes.data.session._id);
        }
      }
    } catch (e) {
      showBoundary(e);
    }

  }, [viModId, viSessionId, VI, showBoundary]); 

  useEffect(() => {
    getSession();
  }, [getSession]);


  return (<div className="role-play-container d-flex flex-column justify-content-start align-items-center">
      <Header />
      {viSessionId ? (
        <AvatarVideo sessionId={viSessionId} modId={viModId} mod={VI} audioRef={audioRef} />
      ) : (
        <div></div>
      )}
      
      {/*<SwitchMode step={step} handleSwitchMode={handleSwitchMode} />*/}
    </div>
  );
}

export default VirtualInstructor;