import React, { useRef } from 'react';
import '../stylesheets/Modal.scss';

const Modal = ({ isOpen, onClose, children, isTranscript }) => {
  const modalRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (onClose && modalRef.current && !modalRef.current.contains(e.target)) {
      onClose(e);
    }

    e.stopPropagation();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="app-modal-overlay d-flex">
      <div className={isTranscript ? `${"transcript-modal d-flex flex-wrap" }` : `${"app-modal d-flex flex-wrap" }`} onClick={handleOutsideClick}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
