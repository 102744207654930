import '../stylesheets/SummaryModules.scss';
import { useEffect, useCallback, useState } from 'react';
import {getSummaryModulesByTenant} from '../../utils/fetch/llmFetching';
import {getLocal} from '../../utils/dataFetching';
import { useErrorBoundary } from "react-error-boundary";

const SummaryModules = ({ datestart, dateend }) => {
  const { showBoundary } = useErrorBoundary();

  const [tenant,] = useState(getLocal('tenant'));
  const [modules, setModules] = useState(null);

  const getData = useCallback(async (tenantId) => {
    try {
      const response = await getSummaryModulesByTenant(tenantId);

      setModules(response);
    } catch (e) {
      showBoundary(e);
      return null;
    }
  }, [showBoundary]);

  useEffect(() => {
    getData(tenant._id);
  }, [tenant, getData]);

  return (
    <div className="summaries-container">
      <div className="logo-container"></div>
      <h3>Session Summaries</h3>
      <div className="summaries-scrollable">
        {modules && modules.map(module => <button key={module._id} type="button" onClick={() => window.location = `/${tenant.slug}/session-summaries/${module._id}?datestart=${datestart}&dateend=${dateend}`}>{module.title}</button>)}
      </div>
    </div>
  );
};

export default SummaryModules;
