import React, {useState} from 'react';
import '../stylesheets/Header.scss';
import Modal from './Modal';
import {getLocal} from '../../utils/dataFetching';

function Header () {
  const [isEnding, setIsEnding] = useState(false);
  const [tenant,] = useState(getLocal('tenant'));

  return (
    <>
      <div className="flex-grow-1 header-container my-2 py-4 d-flex justify-content-center align-items-center">
        <button className="app-link not-mobile" onClick={() => setIsEnding(true)}>&lt; menu</button>
        <button className="app-link mobile" onClick={() => setIsEnding(true)}>&lt; menu</button>
      </div>
      <Modal isOpen={isEnding}>
        <span>Do you want to exit this session?</span>
        <div className="d-flex">
          <button onClick={() => window.location = '/' + tenant?.slug + '/menu'}>Exit</button>
          <button onClick={() => setIsEnding(false)}>Continue</button>
        </div>
      </Modal>
    </>
  );
}

export default Header;
